import React from 'react'
import { scroller } from 'react-scroll'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import Seo from './../components/Layout/Seo'
import Banner from '../components/Banner'
import LinkedImage from '../components/LinkedImage'
import { Related, SwiperCarousel } from './../components/Shared'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 3rem 0;

    > div {
        margin-bottom: 3rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
    
    ${props => props.theme.above.desktop`
        padding: 12rem 0;

        > div {
            margin-bottom: 12rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    `}
`

const Text = styled(motion.div)`
    width: 100%;
    max-width: 62.5rem;
    
    padding: 0 3rem;

    p {
        margin-bottom: 3rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .Quote {
        display: block;

        font-family: ${props => props.theme.fontFamily.minionMedium};
        font-size: 2.5rem;
        line-height: 3rem;

        color: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        p {
            line-height: 3.5rem;
        }

        .Quote {
            font-size: 2.5rem;
            line-height: 4rem;
        }
    `}
`

const StyledLinkedImage = styled(LinkedImage)`
    width: 100%;
    max-width: 100rem;

    margin: 0 auto;
`

const transition = {
    duration: 0.55,
    damping: 100,
    stiffness: 100,
    ease: 'circOut'
}

const AboutItem = ({
    pageContext: {
        lang,
        langSlug
    },
    data: {
        item: {
            data: {
                about_title,
                about_type,
                about_image,
                about_excerpt,
                body,
                related_items,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image
            }
        },
        page: {
            data: {
                related_title,
                related_subtitle
            }
        }
    }
}) => {

    const [wrapperRef, inView] = useInView({
        triggerOnce: true
    })

    const animation = inView ? 'after' : 'before'

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Banner 
                lang={langSlug}
                image={about_image} 
                title={about_title} 
                type={about_type}
                subtitle={about_excerpt}
                overlayAmount={about_image[0].image_overlay_amount}
                onClick={() => scroller.scrollTo('content', {
                    duration: 1500,
                    delay: 100,
                    smooth: 'easeInOutQuint'
                })}
            />
            {body && (
                <Wrapper ref={wrapperRef} name='content'>
                    {body.map((item, i) =>
                        (() => {
                            switch (item.__typename) {
                                case 'PrismicAboutItemBodyText': return (
                                    <Text
                                        key={i}
                                        dangerouslySetInnerHTML={{ __html: item.primary.text_content.html }}
                                        animate={animation}
                                        variants={{
                                            before: {
                                                opacity: 0
                                            },
                                            after: {
                                                opacity: 1
                                            }
                                        }}
                                        transition={transition}
                                    />
                                )
                                case 'PrismicAboutItemBodyGallery': return (
                                    <SwiperCarousel
                                        key={i}
                                        lang={langSlug}
                                        data={item}
                                        type='gallery'
                                    />
                                )
                                case 'PrismicAboutItemBodyImage': {
                                    if (!item.primary) return
                                    return (
                                        <StyledLinkedImage
                                            key={i}
                                            lang={langSlug}
                                            product={item.primary.product_link && item.primary.product_link.document}
                                            image={item.primary.image}
                                        />
                                    )
                                }
                            }
                        })()
                    )}
                </Wrapper>
            )}
            {related_items && related_items.length > 0 && (
                <Related
                    lang={langSlug}
                    type='about'
                    data={related_items}
                    title={related_title.text}
                    subtitle={related_subtitle}
                />
            )}
        </>
    )
}

export const QUERY_ABOUTITEM = graphql`
    query AboutItem($id: String!, $lang: String!) {
        item: prismicAboutItem(id: { eq: $id }) {
            id
            lang
            ...AboutItemQuery
        }
        page: prismicAboutPage(lang: { eq: $lang }) {
            data {
                related_title {
                    text
                }
                related_subtitle
            }
        }
    }
`

export default AboutItem