import React, { useContext, useState, useRef, useLayoutEffect } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import styled from "styled-components"
import Img from "gatsby-image"

import Title from "./Title"
import ButtonCircle from "./Buttons/ButtonCircle"
import ProductShortcut from "./ProductShortcut"

import { DictionaryContext } from "./../contexts/dictionary"

const Wrapper = styled.div`
    position: relative;

    width: 100%;
    height: 50vh;

    overflow: hidden;

    ${props => props.theme.above.desktop`
        height: 100vh;
        max-height: 120rem;
    `}
`

const BackgroundWrapper = styled(motion.div)`
    position: absolute;

    width: 100%;
    height: 100%;
`

const Background = styled(Img)`
    width: 100%;
    height: 100%;

    picture {
        img {
            opacity: 1 !important;
        }
    }

    &:after {
        content: "";
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: ${props =>
            props.overlay
                ? `rgba(0, 0, 0, ${props.overlay / 10})`
                : `rgba(0, 0, 0, 0.35)`};
    }
`

const Header = styled.div`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 100%;

    text-align: center;

    padding: 8rem 3rem 3rem;

    ${props => props.theme.above.desktop`
        padding: 3rem;
    `}
`

const StyledTitle = styled(Title)`
    margin: 0 auto;

    .subtitle {
        color: ${props => props.theme.colors.white};
    }

    .line {
        display: none;
    }

    ${props =>
        props.reversed &&
        `
        display: flex;
        flex-direction: column-reverse;
    `};

    ${props => props.theme.below.desktop`
        padding: 0;

        text-align: center;

        .subtitle {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        .title {
            font-size: 3.5rem;
            line-height: 3.7rem;
        }
    `}
`

const StyledButtonCircle = styled(ButtonCircle)`
    display: none;

    position: absolute;

    bottom: 6rem;
    left: 6rem;

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const StyledProductShortcut = styled(ProductShortcut)`
    display: none;

    position: absolute;

    bottom: 6rem;
    right: 6rem;

    ${props => props.theme.above.desktop`
        display: flex;
    `}
`

const Banner = ({
    className,
    lang,
    title,
    subtitle,
    image,
    type,
    overlayAmount,
    onClick,
    reversed,
}) => {
    // const [elementTop, setElementTop] = useState(0);
    // const ref = useRef(null);
    // const { scrollY } = useViewportScroll();

    // const y = useTransform(scrollY, [elementTop, elementTop - 1], [0, -1], {
    //     clamp: false
    // });

    // useLayoutEffect(() => {
    //     const element = ref.current;
    //     setElementTop(element.offsetTop);
    // }, [ref]);

    const dictionary = useContext(DictionaryContext)

    return (
        <Wrapper className={`${className || ""} ${type.toLowerCase()}`}>
            <BackgroundWrapper>
                <Background
                    fluid={image[0].image.fluid}
                    objectFit="cover"
                    alt={image[0].image.alt}
                    overlay={overlayAmount}
                    fadeIn={false}
                    loading="eager"
                />
            </BackgroundWrapper>
            <Header className={type.toLowerCase()}>
                <StyledTitle
                    title={title.text}
                    subtitle={subtitle.text}
                    alignment="center"
                    reversed={reversed ? true : false}
                />
            </Header>
            {type != "Video" && (
                <StyledButtonCircle
                    type="arrow"
                    direction="down"
                    label={dictionary.scroll_down}
                    onClick={onClick}
                />
            )}
            {image[0].product_link && (
                <StyledProductShortcut
                    lang={lang}
                    product={image[0].product_link.document}
                    open={false}
                />
            )}
        </Wrapper>
    )
}

export default Banner
